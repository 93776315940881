import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './Counter.css';

interface CounterProps {
    onIncrement: (count: number) => void;
    tapCount: number;
    activeBooster: number;
    onTouch: (event: React.TouchEvent<HTMLDivElement>) => void;
}

const Counter: React.FC<CounterProps> = ({ onIncrement, tapCount, activeBooster, onTouch }) => {
    const [hourlyLimit] = useState(1000);
    const [hourlyTaps, setHourlyTaps] = useState(() => {
        const savedTaps = localStorage.getItem('hourlyTaps');
        return savedTaps ? parseInt(savedTaps, 10) : 0;
    });
    const [, setHourlyProgress] = useState(() => {
        const savedTaps = localStorage.getItem('hourlyTaps');
        const initialTaps = savedTaps ? parseInt(savedTaps, 10) : 0;
        return hourlyLimit - initialTaps;
    });
    const [floatingNumbers, setFloatingNumbers] = useState<{ id: number; value: number; x: number; y: number; color: string }[]>([]);
    const [idCounter, setIdCounter] = useState(0);
    const [remainingTime, setRemainingTime] = useState('');

    useEffect(() => {
        const now = Date.now();
        const lastReset = localStorage.getItem('lastHourlyReset');

        if (lastReset) {
            const elapsed = now - parseInt(lastReset, 10);
            const hoursElapsed = elapsed / 3600000;

            if (hoursElapsed >= 1) {
                setHourlyTaps(0);
                localStorage.setItem('hourlyTaps', '0');
                localStorage.setItem('lastHourlyReset', now.toString());
            }
        } else {
            localStorage.setItem('lastHourlyReset', now.toString());
        }

        const interval = setInterval(() => {
            updateRemainingTime();
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        localStorage.setItem('hourlyTaps', hourlyTaps.toString());
        setHourlyProgress(hourlyLimit - hourlyTaps);
        updateRemainingTime();
    }, [hourlyTaps, hourlyLimit]);

    const updateRemainingTime = () => {
        const lastReset = localStorage.getItem('lastHourlyReset');
        if (lastReset) {
            const now = Date.now();
            const nextReset = parseInt(lastReset, 10) + 3600000;
            const timeRemaining = nextReset - now;
            const minutesRemaining = Math.floor(timeRemaining / 60000);
            const secondsRemaining = Math.floor((timeRemaining % 60000) / 1000);
            setRemainingTime(`${minutesRemaining} minutes and ${secondsRemaining} seconds remaining`);
        }
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const handleTap = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
        event.preventDefault();  // Prevent default behavior
        if (hourlyTaps < hourlyLimit) {
            const increment = activeBooster || 1;
            onIncrement(increment);
            setHourlyTaps(prev => prev + 1);

            const rect = event.currentTarget.getBoundingClientRect();
            const x = event.type === 'touchstart'
                ? (event as React.TouchEvent<HTMLDivElement>).touches[0].clientX - rect.left
                : (event as React.MouseEvent<HTMLDivElement>).clientX - rect.left;
            const y = event.type === 'touchstart'
                ? (event as React.TouchEvent<HTMLDivElement>).touches[0].clientY - rect.top
                : (event as React.MouseEvent<HTMLDivElement>).clientY - rect.top;

            setFloatingNumbers(prev => [...prev, { id: idCounter, value: increment, x, y, color: getRandomColor() }]);
            setIdCounter(prev => prev + 1);

            setTimeout(() => {
                setFloatingNumbers(prev => prev.filter(num => num.id !== idCounter));
            }, 4000);
        } else {
            Swal.fire({
                title: 'Hourly Tap Limit Reached',
                text: `You have reached the hourly tap limit. ${remainingTime}`,
                icon: 'warning',
                confirmButtonText: 'OK',
                width: '200px', // Set the width of the popup
                background: '#BE38EB', // Set the background color of the popup
                confirmButtonColor: '#6D0F8D', // Set the color of the confirm button
                cancelButtonColor: '#d33', // Set the color of the cancel button (if using cancel button)
                customClass: {
                    confirmButton: 'my-custom-button-class', // Custom class for button
                },
            });
        }
    };

    return (
        <div className="counter-container">
            <h1 className="counter-value">
                <img src="/images/icon.png" alt="Icon" className="counter-icon" />
                {tapCount}
            </h1>
            <div
                className="counter-button"
                onClick={handleTap}
                onTouchStart={(e) => {
                    e.preventDefault(); // Prevent any default touch behavior
                    handleTap(e);
                }}
                onContextMenu={(e) => e.preventDefault()}  // Prevent right-click menu
                onMouseDown={(e) => e.button === 2 && e.preventDefault()}  // Prevent right-click actions
            >
                <img src="/images/tap_button.png" alt="Tap Me" />
                <div className="floating-numbers-container">
                    {floatingNumbers.map(num => (
                        <div key={num.id} className="floating-number" style={{ left: num.x, top: num.y, color: num.color }}>
                            +{num.value}
                        </div>
                    ))}
                </div>
            </div>
            <div className="hourly-progress">
                <p>Hourly Taps: {hourlyTaps}/{hourlyLimit}</p>
            </div>
        </div>
    );
};

export default Counter;
