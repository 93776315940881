import React, { useState, useEffect } from 'react';
import './MinersPage.css';
import { useTonConnectUI } from '@tonconnect/ui-react';
import axios from 'axios';

interface MinersPageProps {
    tapCount: number;
    miners: { [key: string]: boolean }; // GPU miners
    ASIC_Miner: { [key: string]: boolean }; // ASIC miners
    onBuyMiner: (level: string, price: number, type: 'GPU' | 'ASIC') => void; // Updated to include type
}

const MinersPage: React.FC<MinersPageProps> = ({ tapCount, miners, ASIC_Miner, onBuyMiner }) => {
    const [wallet, setWallet] = useState<any>(null);
    const [tonConnectUI] = useTonConnectUI();
    const [txStatus, setTxStatus] = useState<string | null>(null);
    const [transactionBoc, setTransactionBoc] = useState<string | null>(null); // State to hold transaction BOC

    // Load wallet state from localStorage when the component mounts
    useEffect(() => {
        const savedWallet = localStorage.getItem('wallet');
        if (savedWallet) {
            setWallet(JSON.parse(savedWallet));
        }
    }, []);

    const minerData = [
        { level: '2x', price: 20 },
        { level: '3x', price: 30 },
        { level: '4x', price: 50 },
        { level: '5x', price: 100 },
        { level: '10x', price: 2000000 },
    ];

    const tonItems = [
        { level: '25x', price: 0.01 },
        { level: '50x', price: 0.05 },
        { level: '75x', price: 0.1 },
        { level: '100x', price: 0.2 },
    ];

    const handleBuyClick = (level: string, price: number, type: 'GPU' | 'ASIC') => {
        if (tapCount < price) {
            alert('Insufficient tap count to buy this miner.');
            return;
        }

        if (!canBuyMiner(level, type)) {
            alert('You must buy lower-level miners first.');
            return;
        }

        onBuyMiner(level, price, type);
    };

    const handleBuyWithTonWalletClick = async (level: string, price: number) => {
        if (!wallet) {
            alert('Please connect your Ton Wallet first.');
            return;
        }

        const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 60,
            messages: [
                {
                    address: "0QCinnYcffl9mLGHFy-DM_kMfSiL4hAnvyWdIn96Zc6ux4B8", // Replace with your testnet address
                    amount: (price * 1000000000).toString(),
                },
            ],
        };

        try {
            const result = await tonConnectUI.sendTransaction(transaction, {
                modals: ['before', 'success', 'error'],
                notifications: ['before', 'success', 'error'],
            });

            const boc = result?.boc;
            const address = wallet.account.address;

            if (boc) {
                setTransactionBoc(boc);

                await new Promise(resolve => setTimeout(resolve, 25000)); // Wait 15 seconds

                const createdLt = await getTransactionCreatedLt(address, boc);
                if (createdLt) {
                    const txConfirmed = await waitForTransactionConfirmation(createdLt, 120000);
                    if (txConfirmed) {
                        alert('Transaction confirmed successfully!');
                        await fetch('/api/update-database', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                address: wallet.account.address,
                                amount: price,
                                item: `ASIC ${level}`,
                            }),
                        });
                    } else {
                        alert('Transaction confirmation failed after 2 minutes.');
                    }
                } else {
                    alert('Failed to get created_lt from transaction.');
                }
            } else {
                alert('Failed to get BOC from transaction result.');
            }
        } catch (error) {
            console.error('Transaction error:', error);
            alert('An error occurred during the transaction. Please try again.');
        }
    };

    const getTransactionCreatedLt = async (address: string, boc: string) => {
        const apiKey = '43e3edf00e1c9b0297165af319dfa5f991d6fa8f26411e2f2a64458eb58d44ca'; // Replace with your actual API key
        const url = `https://testnet.toncenter.com/api/v3/transactions?account=${address}&limit=1`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                },
            });
            const data = response.data;

            if (data && data.transactions && data.transactions.length > 0) {
                const transaction = data.transactions.find((tx: { in_msg: { hash: string } }) => tx.in_msg && tx.in_msg.hash === boc);

                if (transaction) {
                    return transaction.lt;
                } else {
                    throw new Error('No matching transactions found.');
                }
            } else {
                throw new Error('No transactions found for the given address.');
            }
        } catch (error) {
            console.error('Error fetching transaction details:', error);
            throw error;
        }
    };

    const waitForTransactionConfirmation = async (createdLt: any, maxWaitTime: number) => {
        const startTime = Date.now();
        while (Date.now() - startTime < maxWaitTime) {
            try {
                const data = await fetchTransactionStatus(createdLt);
                if (data.transactions && data.transactions.length > 0) {
                    const transaction = data.transactions.find((tx: { lt: any; }) => tx.lt === createdLt);
                    if (transaction) {
                        setTxStatus(transaction.end_status);
                        if (transaction.end_status === 'finalized') {
                            return true;
                        }
                    }
                }
            } catch (error) {
                console.error('Error checking transaction status:', error);
            }
            await new Promise(resolve => setTimeout(resolve, 5000)); // Check every 5 seconds
        }
        return false;
    };

    const fetchTransactionStatus = async (createdLt: any) => {
        const account = wallet.account.address; // Assuming wallet is defined somewhere in your code
        const url = `/proxy/toncenter?account=${account}&limit=1`;

        try {
            const response = await axios.get(url, {
                headers: {
                    'accept': 'application/json',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching transaction status:', error);
            throw error;
        }
    };


    const canBuyMiner = (level: string, type: 'GPU' | 'ASIC') => {
        const levels = type === 'GPU' ? ['2x', '3x', '4x', '5x', '10x'] : ['25x', '50x', '75x', '100x'];
        const index = levels.indexOf(level);

        for (let i = 0; i < index; i++) {
            if (type === 'GPU' && !miners[levels[i]]) {
                return false;
            }
            if (type === 'ASIC' && !ASIC_Miner[levels[i]]) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className="miners-page">
            <div className="caption">Don't want to waste <br />time on tapping?<br /> Buy GPU Miners</div>
            <div className="miners-container">
                {minerData.map((miner, index) => (
                    <div key={index} className={`miner-item ${miners[miner.level] ? 'bought' : ''}`}>
                        <img
                            src={`/images/GPU_${miner.level}.png`}
                            alt={`GPU ${miner.level}`}
                            className="miner-image"
                        />
                        <div className="miner-details">
                            <span>GPU {miner.level}</span>
                            <span className="miner-price">Price: {miner.price}</span>
                            <button
                                onClick={() => handleBuyClick(miner.level, miner.price, 'GPU')}
                                disabled={miners[miner.level] || !canBuyMiner(miner.level, 'GPU')}
                                className="buy-button"
                            >
                                {miners[miner.level] ? 'Bought' : 'Buy'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            <div className="caption">ASIC Miners</div>

            <div className="buy-with-ton-container">
                {tonItems.map((item, index) => (
                    <div key={index} className="miner-item">
                        <img
                            src={`/images/ASIC_${item.level}.png`}
                            alt={`ASIC ${item.level}`}
                            className="miner-image"
                        />
                        <div className="miner-details">
                            <span>ASIC {item.level}</span>
                            <span className="miner-price">Price: {item.price} TON</span>
                            <button
                                onClick={() => handleBuyWithTonWalletClick(item.level, item.price)}
                                className="buy-button"
                            >
                                Buy with Ton Wallet
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {txStatus && <div className="transaction-status">Transaction Status: {txStatus}</div>}
        </div>
    );
};

export default MinersPage;
