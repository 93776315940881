// src/components/NavButtons.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './NavButtons.css';

interface NavButtonProps {
    name: string;
    icon: string;
    onClick: () => void;
    profilePicture?: string; // Optional prop for profile picture
}

const NavButton: React.FC<NavButtonProps> = ({ name, icon, onClick, profilePicture }) => {
    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault(); // Prevents the context menu from appearing on long press
    };

    return (
        <div className="nav-button" onClick={onClick} onContextMenu={handleContextMenu}>
            <img src={profilePicture || icon} alt={name} className="nav-icon" />
            <span className="nav-text">{name}</span>
        </div>
    );
};

interface NavButtonsProps {
    onNavigate: (screen: string) => void;
    telegramUsername: string; // Updated prop to telegramUsername
}

const NavButtons: React.FC<NavButtonsProps> = ({ onNavigate, telegramUsername }) => {
    const [profilePicture, setProfilePicture] = useState<string>('');

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`https://babypaal.online:5000/user/${telegramUsername}`);
                const { profilePicture } = response.data;
                setProfilePicture(profilePicture);
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, [telegramUsername]);

    return (
        <div className="nav-buttons">
            <NavButton name="Home" icon="/images/home.png" onClick={() => onNavigate('Home')} />
            <NavButton name="Invite" icon="/images/invite.png" onClick={() => onNavigate('Invite')} />
            <NavButton name="Tasks" icon="/images/task.png" onClick={() => onNavigate('DailyTasks')} />
            <NavButton name="Booster" icon="/images/booster.png" onClick={() => onNavigate('Booster')} />
            <NavButton name="Miners" icon="/images/GPU.png" onClick={() => onNavigate('Miners')} />
            <NavButton name="Profile" icon="/images/profile.png" onClick={() => onNavigate('Profile')} profilePicture={profilePicture} />
        </div>
    );
};

export default NavButtons;
