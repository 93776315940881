import React, { useState } from 'react';
import './Header.css';

interface HeaderProps {
    username: string;
    profilePhotoUrl: string;
    tapCount: number; // New prop to pass the total tap count

}

const Header: React.FC<HeaderProps> = ({ username, profilePhotoUrl, tapCount }) => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    const getLevel = (count: number): string => {
        if (count >= 10000000000) return '8';
        if (count >= 100000000) return '7';
        if (count >= 10000000) return '6';
        if (count >= 1000000) return '5';
        if (count >= 100000) return '4';
        if (count >= 10000) return '3';
        if (count >= 1000) return '2';
        if (count >= 100) return '1';
        return 'Beginner';
    };
    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    return (
        <header className="header">
            <div className="header-column header-left">
                {profilePhotoUrl ? (
                    <img
                        src={profilePhotoUrl}
                        alt={`${username}'s profile`}
                        className="profile-picture"
                        onError={(e) => {
                            console.error('Error loading image:', e);
                            e.currentTarget.src = '/images/profile.png'; // Fallback image
                        }}
                    />
                ) : (
                    <div className="profile-photo-placeholder">No Photo</div>
                )}
                <p className="username">{username || 'Guest'}</p>
            </div>
            <div className="header-column header-center">
                <h2 className="level-title">Your Level</h2>
                <p className="level-box">{getLevel(tapCount)}</p>
            </div>
            <div className="header-column header-right">
                <button className="header-right-button" onClick={togglePopup}>
                    <img src="/images/help.png" alt="Button" className="button-icon" />
                </button>
            </div>

            {isPopupVisible && (
                <div className="popup">
                    <div className="popup-content">
                        <h2>Game Instructions</h2>
                        <p>Here are the instructions on how to play the game...</p>
                        <button className="close-button" onClick={togglePopup}>Close</button>
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
