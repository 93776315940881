import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import './InviteScreen.css'; // Import the CSS file for styling

interface InviteScreenProps {
    chatId: number;
}

const InviteScreen: React.FC<InviteScreenProps> = ({ chatId }) => {
    const [referralLink, setReferralLink] = useState<string | null>(null);
    const [referralCount, setReferralCount] = useState<number>(0);
    const [referralReward, setReferralReward] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchReferralData = async () => {
            try {
                const response = await axios.get('https://babypaal.online:5000/api/get-referral-link', {
                    params: { chatId },
                });

                const { link, referralCount, referralReward } = response.data;

                setReferralLink(link);
                setReferralCount(referralCount);
                setReferralReward(referralReward);
            } catch (err) {
                setError('Failed to fetch referral data. Please try again later.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchReferralData();
    }, [chatId]);

    const handleShare = () => {
        if (referralLink) {
            const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=Check%20out%20this%20referral%20link!`;
            window.open(telegramShareUrl, '_blank');
        }
    };

    const handleCopyLink = () => {
        if (referralLink) {
            navigator.clipboard.writeText(referralLink);
            Swal.fire({
                title: 'Copied!',
                text: 'Referral link copied to clipboard!',
                icon: 'success',
                confirmButtonText: 'OK',
                width: '200px', // Set the width of the popup

                background: '#15B5D1', // Set the background color of the popup
                confirmButtonColor: '#09545E', // Set the color of the confirm button
                cancelButtonColor: '#d33', // Set the color of the cancel button (if using cancel button)
            });

        }
    };

    return (
        <div className="invite-screen">
            {loading && <p className="loading">Loading your referral data...</p>}
            {error && <p className="error">{error}</p>}
            {referralLink && (
                <div className="invite-container">
                    <h2>Your Referral Link</h2>
                    <p>Share this link to invite your friends:</p><br></br><br></br>
                    <div className="link-container">
                        <input type="text" readOnly value={referralLink} className="link-input" />
                    </div>
                    <div className="button-container">
                        <button onClick={handleCopyLink} className="copy-button">
                            Copy Link
                        </button>
                        <button onClick={handleShare} className="share-button">
                            Share
                        </button>
                    </div>
                    <div className="referral-info">
                        <p>Total Referrals: {referralCount}</p>
                        <p>Total Referral Reward: {referralReward}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InviteScreen;
