import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DailyTaskScreen.css';

type Task = {
    _id: string;
    description: string;
    action: string;
    completed: boolean;
    link: string;
};

interface DailyTaskScreenProps {
    chatId: number;
}

const DailyTaskScreen: React.FC<DailyTaskScreenProps> = ({ chatId }) => {
    const [userTasks, setUserTasks] = useState<Task[]>([]);
    const [totalRewards, setTotalRewards] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchDailyTasks = async () => {
            try {
                const response = await axios.get(`https://babypaal.online:5000/api/user/${chatId}/daily-tasks`);
                console.log('Fetched tasks response:', response.data); // Debugging

                // Use task_reward from the response for total rewards
                setUserTasks(response.data.dailyTasks || []);
                setTotalRewards(response.data.totalRewards || 0);
            } catch (err) {
                let errorMessage = 'Failed to fetch tasks';
                if (axios.isAxiosError(err)) {
                    errorMessage = err.response?.data?.message || errorMessage;
                } else if (err instanceof Error) {
                    errorMessage = err.message;
                }
                console.error('Error fetching daily tasks:', err);
                setError(errorMessage);
            } finally {
                setLoading(false); // Ensure loading state is reset
            }
        };

        if (chatId) {
            fetchDailyTasks();
        } else {
            setError('Invalid chat ID');
            setLoading(false);
        }
    }, [chatId]);

    const handleTaskCompletion = async (task: Task) => {
        try {
            const response = await axios.post(`https://babypaal.online:5000/api/user/${chatId}/daily-task`, {
                taskId: task._id,
                completed: true,
            });
            console.log('Task completion response:', response.data); // Debugging

            // Update tasks and total rewards after completion
            setUserTasks(response.data.dailyTasks || []);
            setTotalRewards(response.data.totalRewards || 0);
        } catch (err) {
            let errorMessage = 'Failed to complete task';
            if (axios.isAxiosError(err)) {
                errorMessage = err.response?.data?.message || errorMessage;
            } else if (err instanceof Error) {
                errorMessage = err.message;
            }
            console.error('Error updating task status:', err);
            setError(errorMessage);
        }
    };

    if (loading) {
        return <div>Loading tasks...</div>;
    }

    return (
        <div className="daily-task-container">
            <h2>Total Rewards: {totalRewards}</h2>
            {error && <p className="error-message">{error}</p>}
            {userTasks.length === 0 ? (
                <p>No tasks available</p>
            ) : (
                userTasks.map(task => (
                    <div key={task._id} className="daily-task-item">
                        <div>{task.description}</div>
                        <button
                            onClick={() => {
                                window.open(task.link, '_blank');
                                if (!task.completed) {
                                    handleTaskCompletion(task);
                                }
                            }}
                            disabled={task.completed}
                            className={`daily-task-button ${task.completed ? 'completed' : ''}`}
                        >
                            {task.completed ? 'Completed' : 'Complete Task'}
                        </button>
                    </div>
                ))
            )}
        </div>
    );
};

export default DailyTaskScreen;
