import React from 'react';
import './BoosterPage.css';

interface BoosterPageProps {
    tapCount: number;
    boosters: { [key: string]: boolean };
    onBuyBooster: (level: string, price: number) => void;
}

const BoosterPage: React.FC<BoosterPageProps> = ({ tapCount, boosters, onBuyBooster }) => {
    const boosterData = [
        { level: '2x', price: 2 },
        { level: '3x', price: 3 },
        { level: '5x', price: 5 },
        { level: '10x', price: 10 },
        { level: '20x', price: 20 },
    ];

    const handleBuyClick = (level: string, price: number) => {
        if (tapCount < price) {
            alert('Insufficient tap count to buy this booster.');
            return;
        }

        if (!canBuyBooster(level)) {
            alert('You must buy lower level boosters first.');
            return;
        }

        onBuyBooster(level, price);
    };

    const canBuyBooster = (level: string) => {
        const levels = ['2x', '3x', '5x', '10x', '20x'];
        const index = levels.indexOf(level);

        for (let i = 0; i < index; i++) {
            if (!boosters[levels[i]]) {
                return false;
            }
        }
        return true;
    };

    return (
        <div className="booster-page">
            <div className="tap-count">
                Boosters: <br /> Multiply your per tap income! <br></br>eg. 10x boosters = 10x per tap
                <br />
            </div>
            <div className="boosters-container">
                {boosterData.map((booster, index) => (
                    <div key={index} className={`booster-item ${boosters[booster.level] ? 'bought' : ''}`}>
                        <img
                            src={`/images/booster_${booster.level}.png`}
                            alt={`Booster ${booster.level}`}
                            className="booster-image"
                        />
                        <div className="booster-details">
                            <span>Booster {booster.level}</span>
                            <span className="booster-price">Price: {booster.price} </span> {/* Show price */}
                            <button
                                onClick={() => handleBuyClick(booster.level, booster.price)}
                                disabled={boosters[booster.level] || !canBuyBooster(booster.level)}
                                className="buy-button"
                            >
                                {boosters[booster.level] ? 'Bought' : 'Buy'}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <br /><br /><br /><br /><br /><br />
        </div>
    );
};

export default BoosterPage;
