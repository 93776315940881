import React, { useState, useEffect } from 'react';
import './ProfileScreen.css';
import ConnectTONWallet from './ConnectTONWallet';

interface ProfileScreenProps {
    username: string;
    tapCount: number;
    boosters: { [key: string]: boolean };
    miners: { [key: string]: boolean };
    profilePhotoUrl: string;
}

const ProfileScreen: React.FC<ProfileScreenProps> = ({
    username,
    tapCount,
    boosters,
    miners,
    profilePhotoUrl,
}) => {
    const [wallet, setWallet] = useState<any>(null);

    // Load wallet state from localStorage when the component mounts
    useEffect(() => {
        const savedWallet = localStorage.getItem('wallet');
        if (savedWallet) {
            setWallet(JSON.parse(savedWallet));
        }
    }, []);

    // Save wallet state to localStorage whenever it changes
    useEffect(() => {
        if (wallet) {
            localStorage.setItem('wallet', JSON.stringify(wallet));
        }
    }, [wallet]);

    const totalTapSpeed =
        Object.keys(boosters).reduce(
            (acc, key) => (boosters[key] ? acc + parseInt(key) : acc),
            1
        ) +
        Object.keys(miners).reduce(
            (acc, key) => (miners[key] ? acc + parseInt(key) : acc),
            0
        );

    return (
        <div className="profile">
            <div className="profile-photo-container">
                {profilePhotoUrl ? (
                    <img
                        src={profilePhotoUrl}
                        alt={`${username}'s profile`}
                        className="profile-photo-image"
                        onError={(e) => {
                            console.error('Error loading image:', e);
                            e.currentTarget.src = '/images/profile.png'; // Fallback image
                        }}
                    />
                ) : (
                    <div className="profile-photo-placeholder">No Photo</div>
                )}
            </div>

            {/* Wallet connect button below the profile picture */}
            <div className="wallet-connect-button">
                <ConnectTONWallet setWallet={setWallet} />
            </div>

            <div className="profile-info">
                <div className="profile-item">
                    <span className="profile-label">Username:</span>
                    <span className="profile-value">{username || 'N/A'}</span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">Tap Count:</span>
                    <span className="profile-value">{tapCount}</span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">Boosters:</span>
                    <span className="profile-value">
                        {Object.keys(boosters).filter((key) => boosters[key]).join(', ') || 'None'}
                    </span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">GPU:</span>
                    <span className="profile-value">
                        {Object.keys(miners).filter((key) => miners[key]).join(', ') || 'None'}
                    </span>
                </div>
                <div className="profile-item">
                    <span className="profile-label">Tap Speed:</span>
                    <span className="profile-value">{totalTapSpeed}</span>
                </div>
            </div>

            {/* Adjust spacing using CSS instead of <br> tags */}
            <div className="profile-footer-spacing"></div>
        </div>
    );
};

export default ProfileScreen;
