import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import Header from './components/Header';
import Counter from './components/Counter';
import NavButtons from './components/NavButtons';
import BoosterPage from './components/BoosterPage';
import MinersPage from './components/MinersPage';
import ProfileScreen from './components/ProfileScreen';
import InviteScreen from './components/InviteScreen';

import WebApp from '@twa-dev/sdk';
import axios from 'axios';
import { TonConnectUIProvider } from '@tonconnect/ui-react'; // Import TON Connect components
import DailyTaskScreen from './components/DailyTasksScreen';
// import EntrypointPage from './components/EntrypointPage'; // Adjust the import path accordingly

interface UserData {
  tapCount: number;
  boosters: { [key: string]: boolean };
  miners: { [key: string]: boolean };
  ASIC_Miner: { [key: string]: boolean }; // Updated to match expected type
  chatId: number;
  username: string;
  profilePhotoUrl: string;
}


const App: React.FC = () => {
  const [activeScreen, setActiveScreen] = useState<string>('Home');
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<'DailyTasks' | 'OtherScreen'>('DailyTasks');

  const [userData, setUserData] = useState<UserData>({
    tapCount: 0,
    boosters: {
      '2x': false,
      '3x': false,
      '5x': false,
      '10x': false,
      '20x': false,
    },
    miners: {
      '2x': false,
      '3x': false,
      '4x': false,
      '5x': false,
      '10x': false,
    },
    ASIC_Miner: { // Initialize ASIC miners
      '25x': false,
      '50x': false,
      '75x': false,
      '100x': false,
    },
    chatId: 0,
    username: '',
    profilePhotoUrl: '',
  });

  useEffect(() => {
    // Fetch user data from the backend or localStorage and update state
    // For example, using a placeholder function:
    const fetchUserData = async () => {
      const response = await axios.get('/api/get-user-data');
      setUserData(response.data);
    };

    fetchUserData();
  }, []);



  useEffect(() => {
    if (WebApp && WebApp.initDataUnsafe) {
      const { user } = WebApp.initDataUnsafe;

      if (user) {
        const username = user.username || '';  // Default to empty string if undefined
        const chatId = user.id || 0;  // Default to 0 if undefined
        const profilePhotoUrl = user.photo_url || '';  // Default to empty string if undefined

        if (username && chatId) {
          console.log('Fetched username, chatId, and profilePhotoUrl from WebApp:', username, chatId, profilePhotoUrl);

          setUserData((prevData) => ({
            ...prevData,
            username,
            chatId,
            profilePicture: profilePhotoUrl, // Make sure this matches your state field
          }));

          fetchUserData(chatId); // Ensure this function is defined and fetches user data properly
        } else {
          console.error('No Telegram username or chatId found in WebApp.initDataUnsafe.user');
        }
      } else {
        console.error('WebApp.initDataUnsafe.user is not available');
      }
    } else {
      console.error('WebApp or WebApp.initDataUnsafe is not available');
    }
  }, []);


  useEffect(() => {
    const savedData = localStorage.getItem('userData');
    const lastTick = localStorage.getItem('lastTick');
    if (savedData) {
      setUserData(JSON.parse(savedData));
    }
    if (lastTick) {
      localStorage.setItem('lastTick', lastTick); // Restore last tick time
    }
  }, []);

  // Update the fetchUserData function
  const fetchUserData = async (chatId: number) => {
    try {
      console.log('Fetching user data for chatId:', chatId);
      const response = await axios.get(`https://babypaal.online:5000/user/${chatId}`);
      console.log('Fetched user data:', response.data);

      setUserData((prevData) => ({
        ...prevData,
        ...response.data,  // Ensure this includes profilePhotoUrl
      }));
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };



  // Ensure correct field names when saving user data
  const saveUserData = useCallback(async () => {
    try {
      const { username, chatId, profilePhotoUrl, ...updateData } = userData;
      if (chatId) {
        await axios.put(`https://babypaal.online:5000/user/${chatId}`, { ...updateData, username, profilePhotoUrl });
        localStorage.setItem('userData', JSON.stringify(userData));
      } else {
        console.error('Cannot save user data: chatId is undefined');
      }
    } catch (error) {
      console.error('Error saving user data:', error);
    }
  }, [userData]);

  useEffect(() => {
    if (userData.chatId) {
      saveUserData();
    } else {
      console.error('Cannot save user data: chatId is undefined');
    }
  }, [userData, saveUserData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const referrerChatId = searchParams.get('ref');
    if (referrerChatId) {
      console.log('Referral detected with referrerChatId:', referrerChatId);
      handleReferral(referrerChatId);
    }
  }, []);

  const handleReferral = async (referrerChatId: string) => {
    try {
      const response = await axios.post(`https://babypaal.online:5000/api/refer/${referrerChatId}`);
      console.log('Referral response:', response.data);
    } catch (error) {
      console.error('Error handling referral:', error);
    }
  };

  const handleNavigate = (screen: string) => {
    setActiveScreen(screen);
  };


  const handleBuyBooster = (level: string, price: number) => {
    if (userData.tapCount >= price) {
      setUserData((prevData) => ({
        ...prevData,
        boosters: { ...prevData.boosters, [level]: true },
        tapCount: prevData.tapCount - price,
      }));
    }
  };

  const handleBuyMiner = (level: string, price: number, type: 'GPU' | 'ASIC') => {
    if (type === 'GPU') {
      // GPU miners are bought with tap count
      if (userData.tapCount < price) {
        alert('Insufficient tap count to buy this miner.');
        return;
      }

      if (!canBuyMiner(level, type)) {
        alert('You must buy lower-level miners first.');
        return;
      }

      setUserData((prevData) => ({
        ...prevData,
        miners: { ...prevData.miners, [level]: true },
        tapCount: prevData.tapCount - price,
      }));
    } else {
      // ASIC miners are bought with TON
      // Implement TON payment logic here
      // For example, you might show a TonConnect modal or redirect to a payment page

      console.log('Initiating TON payment for ASIC miner');

      // Example placeholder: 
      // Show payment modal or initiate payment process
      // await initiateTonPayment(level, price);

      // After successful payment
      setUserData((prevData) => ({
        ...prevData,
        ASIC_Miner: { ...prevData.ASIC_Miner, [level]: true },
      }));
    }
  };


  // Updated canBuyMiner function to handle both GPU and ASIC miners
  const canBuyMiner = (level: string, type: 'GPU' | 'ASIC') => {
    const levels = type === 'GPU' ? ['2x', '3x', '4x', '5x', '10x'] : ['25x', '50x', '75x', '100x'];
    const index = levels.indexOf(level);

    for (let i = 0; i < index; i++) {
      if ((type === 'GPU' && !userData.miners[levels[i]]) ||
        (type === 'ASIC' && !userData.ASIC_Miner[levels[i]])) {
        return false;
      }
    }
    return true;
  };



  const handleTap = () => {
    let multiplier = 1;

    if (userData.boosters['2x']) multiplier += 2;
    if (userData.boosters['3x']) multiplier += 3;
    if (userData.boosters['5x']) multiplier += 5;
    if (userData.boosters['10x']) multiplier += 10;
    if (userData.boosters['20x']) multiplier += 20;

    setUserData((prevData) => ({
      ...prevData,
      tapCount: parseFloat((prevData.tapCount + multiplier).toFixed(2)),
    }));
  };

  useEffect(() => {
    const HOUR_IN_MS = 3600000;
    const lastTick = localStorage.getItem('lastTick');
    const now = Date.now();
    const tickTime = lastTick ? parseInt(lastTick, 10) : now;
    const elapsed = now - tickTime;

    // Offline mining
    if (elapsed >= HOUR_IN_MS) {
      const offlineAutoTapIncrement = (elapsed / 1000) * calculateAutoTapIncrement(userData.miners, userData.ASIC_Miner);
      setUserData((prevData) => ({
        ...prevData,
        tapCount: parseFloat((prevData.tapCount + offlineAutoTapIncrement).toFixed(2)),
      }));
      localStorage.setItem('lastTick', now.toString());
    }

    const interval = setInterval(() => {
      const now = Date.now();
      const autoTapIncrement = calculateAutoTapIncrement(userData.miners, userData.ASIC_Miner);
      setUserData((prevData) => ({
        ...prevData,
        tapCount: parseFloat((prevData.tapCount + autoTapIncrement).toFixed(2)),
      }));
      localStorage.setItem('lastTick', now.toString());
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, [userData.miners, userData.ASIC_Miner]);

  const calculateAutoTapIncrement = (
    miners: { [key: string]: boolean },
    ASIC_Miner: { [key: string]: boolean }
  ) => {
    let increment = 0;

    // Calculate increments for GPU miners
    if (miners['2x']) increment += 2;
    if (miners['3x']) increment += 3;
    if (miners['4x']) increment += 4;
    if (miners['5x']) increment += 5;
    if (miners['10x']) increment += 10;

    // Calculate increments for ASIC miners
    if (ASIC_Miner['25x']) increment += 25;
    if (ASIC_Miner['50x']) increment += 50;
    if (ASIC_Miner['75x']) increment += 75;
    if (ASIC_Miner['100x']) increment += 100;

    return increment;
  };

  const handleTouch = (event: React.TouchEvent) => {
    // Prevent touch event from propagating to the whole app
    event.stopPropagation();

    // Handle touch only if the event target is the tap button
    if (event.currentTarget === event.target) {
      const { clientX, clientY } = event.touches[0];
      handleTap();

      const floatingElement = document.createElement('div');
      floatingElement.className = 'floating-number';
      floatingElement.style.left = `${clientX}px`;
      floatingElement.style.top = `${clientY}px`;
      floatingElement.textContent = `+${userData.boosters['2x'] ? 2 : 1}`;
      document.body.appendChild(floatingElement);

      setTimeout(() => {
        floatingElement.style.transition = 'all 1s ease-out';
        floatingElement.style.transform = 'translateY(-200px)';
        floatingElement.style.opacity = '0';
        setTimeout(() => {
          document.body.removeChild(floatingElement);
        }, 1000);
      }, 0);
    }
  };


  const renderContent = () => {
    const activeBooster = Object.keys(userData.boosters)
      .filter((key) => userData.boosters[key])
      .map((key) => parseInt(key.replace('x', ''), 10))
      .reduce((a, b) => Math.max(a, b), 1);

    switch (activeScreen) {
      case 'Invite':
        return <InviteScreen chatId={userData.chatId} />; // Use InviteScreen component
      case 'Home':
        return (

          <Counter tapCount={userData.tapCount} onIncrement={handleTap} onTouch={handleTouch} activeBooster={activeBooster} />
        );
      case 'Booster':
        return (
          <BoosterPage
            tapCount={userData.tapCount}
            boosters={userData.boosters}
            onBuyBooster={handleBuyBooster}
          />
        );
      case 'Miners':
        return (
          <MinersPage
            tapCount={userData.tapCount}
            miners={userData.miners}
            ASIC_Miner={userData.ASIC_Miner} // Pass the ASIC miners to MinersPage
            onBuyMiner={handleBuyMiner} // Updated handleBuyMiner function
          />
        );

      case 'Profile':
        return (
          <ProfileScreen
            username={userData.username}
            tapCount={userData.tapCount}
            boosters={userData.boosters}
            miners={userData.miners}
            profilePhotoUrl={userData.profilePhotoUrl} // Use the correct prop
          />
        );

      case 'DailyTasks':
        return <DailyTaskScreen chatId={userData.chatId} />;
      default:
        return (
          <Counter tapCount={userData.tapCount} onIncrement={handleTap} onTouch={handleTouch} activeBooster={activeBooster} />
        );
    }
  };
  return (
    <TonConnectUIProvider manifestUrl="https://babypaal.online/tonconnect-manifest.json">
      <div className="App">
        {/* Render Header only on Home screen */}
        {activeScreen === 'Home' && (
          <Header
            username={userData.username}
            profilePhotoUrl={userData.profilePhotoUrl}
            tapCount={userData.tapCount}
          />
        )}
        <div className="content">{renderContent()}</div>
        <NavButtons onNavigate={handleNavigate} telegramUsername={userData.username} />


      </div>
    </TonConnectUIProvider>
  );
};

export default App;