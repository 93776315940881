import React, { useEffect, useState } from 'react';
import { TonConnectButton, useTonConnectUI, useIsConnectionRestored, Account } from '@tonconnect/ui-react';
import './Counter.css'; // Import your CSS file

interface ConnectTONWalletProps {
    setWallet: React.Dispatch<React.SetStateAction<any>>;
}

const ConnectTONWallet: React.FC<ConnectTONWalletProps> = ({ setWallet }) => {
    const [tonConnectUI] = useTonConnectUI();
    const connectionRestored = useIsConnectionRestored();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTonProofPayload = async (): Promise<string | null> => {
            try {
                const response = await fetch('/api/get-ton-proof');
                const data = await response.json();
                return data.tonProofPayload || null;
            } catch (error) {
                console.error('Failed to fetch tonProof payload:', error);
                return null;
            }
        };

        const initializeConnection = async () => {
            // Check if the wallet is already connected
            if (tonConnectUI.connected) {
                const wallet = tonConnectUI.getWallets();
                setWallet(wallet);
                setLoading(false);
                return;
            }

            // Enable UI loader while fetching tonProof
            tonConnectUI.setConnectRequestParameters({ state: 'loading' });

            // Fetch tonProof payload from the backend
            const tonProofPayload = await fetchTonProofPayload();

            if (!tonProofPayload) {
                // Remove loader if payload is not available
                tonConnectUI.setConnectRequestParameters(null);
            } else {
                // Set tonProof for the connection request
                tonConnectUI.setConnectRequestParameters({
                    state: 'ready',
                    value: { tonProof: tonProofPayload }
                });
            }

            setLoading(false);
        };

        initializeConnection();

        const handleStatusChange = (wallet: any) => {
            if (wallet) {
                const tonProof = wallet.connectItems?.tonProof;
                if (tonProof && 'proof' in tonProof) {
                    const network = '-239'; // Example for mainnet, adjust as needed
                    checkProofInYourBackend(tonProof.proof, wallet.account, network);
                }
            } else {
                // Handle case when wallet is disconnected if needed
                setWallet(null);
            }

            // Update wallet state in parent
            setWallet(wallet);
        };

        tonConnectUI.onStatusChange(handleStatusChange);

        // Cleanup on component unmount
        return () => {
            tonConnectUI.onStatusChange(handleStatusChange); // Proper cleanup
        };
    }, [tonConnectUI, setWallet]);

    if (!connectionRestored || loading) {
        return <div>Please wait...</div>; // Loader or placeholder
    }

    return (
        <div className="connect-ton-wallet">
            <TonConnectButton />
        </div>
    );
};

export default ConnectTONWallet;

function checkProofInYourBackend(
    proof: {
        timestamp: number;
        domain: { lengthBytes: number; value: string };
        payload: string;
        signature: string;
    },
    account: Account,
    network: string
) {
    fetch('/api/check-proof', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            address: account.address,
            network, // Pass the network information here
            proof,
        }),
    })
        .then(response => response.json())
        .then(data => {
            if (data.token) {
                console.log('Auth token:', data.token);
                // Handle the auth token as needed
            } else {
                console.error('Failed to get auth token:', data);
            }
        })
        .catch(error => {
            console.error('Error checking proof:', error);
        });
}
